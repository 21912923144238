.contacts-section {
    position: relative;
    min-height: calc(100vh - 70px);
    min-width: 100vw;
    display: flex;
    align-items: flex-end;
    text-align: center;
    overflow: hidden;
}

@media (min-width: 390px) and (max-width: 575px) {
    .contacts-section {
        background-position: top center;
    }
}

@media (max-width: 767px) {
    .contacts-section {
        padding-bottom: 0;
    }
}

.contacts-background { 
    height: 100%;
    background: url("../assets/images/contacts.JPG") no-repeat center center / cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;

    display: flex;
    align-items: flex-end;
}

.contacts-background::before {
    position: absolute;
    content: '';
    left: 0;
    top: -1px;
    width: 100%;
    height: 25%;
    background: linear-gradient(0deg,rgba(0,0,0,0) 0,var(--main-background) 100%);
    z-index: 2;
}

.contacts-background::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 25%;
    background: linear-gradient(180deg,rgba(0,0,0,0) 0,var(--main-background) 100%);
    z-index: 2;
}

.contacts-row {
    padding: 20px 20px 0;
}

.title-container {
    /* text-align: start; */
    max-width: 1270px;
    position: relative;
    z-index: 3;
    --bs-gutter-x: 1.5rem;

    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
}

.icon {
    width: 40px;
    height: 40px;
}

.contact-column {
    min-width: 300px;
}

.contacts-title {
    font-size: 30px;
    font-weight: 600;    
    text-shadow: 0px 0px 20px #000;
}

a {
    text-shadow: 2px 2px #000;
    font-size: 18px;
    color: #fff;
    text-align: start;
}

.image-title { 
    position: relative;
    text-align: center;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .image-title {
        margin-bottom: 0;
    }

    .title-container { 
        margin-bottom: 0; 
    }
}
