.main-section {
    position: relative;
    min-height: calc(100vh - 70px);
    min-width: 100vw;
    display: flex;
    align-items: flex-end;
    text-align: center;
    overflow: hidden;
}

@media (min-width: 390px) and (max-width: 575px) {
    .main-section {
        background-position: top center;
    }
}

@media (max-width: 767px) {
    .main-section {
        min-height: 350px;
        padding-bottom: 0;
    }
}

.main-background { 
    height: 100%;
    background: url("../assets/images/main1.jpg") no-repeat center top / cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;

    display: flex;
    align-items: flex-end;
}

@media (max-width: 575px){
    .main-background {
        height: 350px;
        transform: translateX(-50%);
        left: 50%;
    }
}

.main-background::before {
    position: absolute;
    content: '';
    left: 0;
    top: -1px;
    width: 100%;
    height: 25%;
    background: linear-gradient(0deg,rgba(0,0,0,0) 0,var(--main-background) 100%);
    z-index: 2;
}

.main-background::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 25%;
    background: linear-gradient(180deg,rgba(0,0,0,0) 0,var(--main-background) 100%);
    z-index: 2;
}


@media (max-width: 767px) {
    .title-container {
        max-width: 350px;
        margin: auto;
    }
}

.title-container {
    max-width: 1270px;
    position: relative;
    z-index: 3;
    --bs-gutter-x: 1.5rem;

    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
}

.image-title { 
    position: relative;
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .image-title {
        margin-bottom: 0;
    }

    .title-container { 
        margin-bottom: 0; 
    }
}
