.toolbar {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;  
    padding: 0 20px;
    max-width: 100%;    position: sticky;

    background: var(--header-color); 
    color: white;
    height: 70px;
    top: 0px;
    z-index: 1;
    transition: top .3s ease-in-out;  top: -100px;
    box-shadow: 0 4px 2px -2px #161515;
}

.sticky {
    position: sticky;
    top: 0;
}

.menu-overlay {
   position: absolute;
   height: 100vh;
   width: 100vw;
   background: var(--main-background);
   z-index: 10;
   display: block;
   top: 0;
   left: 0;
   transition: 0.3s;
   opacity: 0;
   visibility: hidden;
   z-index: 10;
}

.logo {
    height: 40px;
}

.close-icon {
    height: 20px;
    width: 20px;
    fill: #fff;
}

.menu-overlay.active {
    opacity: 1;
    visibility: visible;
}


.menu-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    height: calc(100% - 40px);
    z-index: 10;
 }

.menu-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
 }

.toolbar .toolbar-button {
    background: transparent;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.toolbar-button.is-active {
    border-bottom: 1px solid white;
}

.visible {
    top: 0;
    transition: top 0.4s ease-out;
}
  
.hidden {
    top: -80px;
    transition: top 0.4s ease-out;
}