.title {
    font-size: 30px;
    font-weight: 600;
    padding:  0 0 20px;
}

.section-text-wrapper { 
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 20px;
    max-width: 600px;
    font-size: 16px;
} 

iframe {
    border: none;
}