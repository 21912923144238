.apply-modal { 
  max-width: 900px;

    .ant-modal-content {
        padding: 0;
        background: var(--main-background);

        .description {
          margin-bottom: 24px;
        }

        .ant-modal-header{
          color: #fff !important;

            padding: 24px;
            margin: 0;
            background: var(--main-background);

        }

        .ant-modal-body {
            padding: 0 24px 24px ;           
        }

        .ant-checkbox{
            display: flex;
            align-self: flex-start;
            top: 6px;
        }        
    }
}

.trigger-btn {
  background: #005bb3;
  border-color: #005bb3;
  color: #fff;
  font-weight: 600;
}


.apply-modal .ant-modal-title {
    color: #428ed7;
    font-weight: 600;
    font-size: 20px;
} 

.ant-input-group-addon,
.ant-input,
.ant-picker,
.ant-picker input,
.ant-picker-suffix,
.ant-select-selector {
  background: var(--main-background) !important;
  color: #fff !important;
}

.ant-input::placeholder,
.ant-select-selection-placeholder {
  color: #fff !important;
  opacity: 0.5;
}

.ant-picker-panel,
.ant-select-dropdown {
  background: #0e0e0e !important;
}

.ant-picker-cell:not(.ant-picker-cell-in-view) {
  opacity: 0.65;
}

.ant-picker-cell-disabled {
  opacity: 0.3 !important;
}

.ant-picker-header,
.ant-picker-header button,
.ant-picker-content th,
.ant-picker-cell,
.ant-picker-cell-in-view,
.ant-select-item,
.ant-modal-content, 
.ant-form-item-label label,
.ant-radio-wrapper,
.ant-checkbox-wrapper:not(.policy-link),
.ant-modal-close,
.ant-select-selection-item {
  color: #fff !important;
}

.ant-select-item-option-selected {
  background-color: #005bb3 !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #005bb3 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: #ffffff !important;
    transition: var(--main-background) 5000s ease-in-out 0s !important;
    box-shadow: inset 0 0 20px 20px var(--main-background) !important;
}


@media screen and (max-width: 680px) {
  .form-column {
    flex: 100% !important; 
    max-width: unset;
  }
}