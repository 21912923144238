.support-way-number {
    font-size: 70px;
    display: flex;
    align-self: flex-start;
    line-height: 70px;
    font-weight: 600;
    color: rgb(126 191 255);
}

.support-way {
    width: calc(100% - 100px);
    color: white
}

.support-way-title {
    font-size: 20px;
    font-weight: 500;
    padding: 0  0 10px  0;
    color: rgb(126 191 255);
}

.donate-wrapper { 
    text-align: center;
    color: rgb(126 191 255);
}

.white-logo  {
    width: 100px;
    height: 100px; 
}

.support-wrapper {
    text-align: flex-start;
    float: left;
    flex: 50%;
    font-size: 16px;
}

.desktop-support-image { 
    padding-top: 20px;
}

.support-image { 
    max-width: 600px;
    width: 100%;
}

.support-way-column {
    min-width: 300px;
    width: 100%;
}

.privat-bank-support-wrapper {
    margin: 0;
    margin-block: 0;
    padding-inline-start: 20px;
    gap: 10px;
}