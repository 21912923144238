.image-carousel {
    background: transparent;
    max-width: 100vw;
    aspect-ratio: 2/1;
    max-height: calc(100vh - 70px);
}

.border-radius-12 {
    border-radius: 12px;
}

.slick-list {
    width: 100%;
}

.slick-track {
    display: flex !important;
    align-items: center;
    justify-content: center;
}
